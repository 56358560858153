// Preventing FOUC (seeing mobile nav briefly on load)
.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}

.navigation {
    border-bottom:1px solid $light-gray;
}
#top-navigation {
    padding: 1.5rem 0;

    #logo {
        max-width: 210px;
    }

    .top-bar {
        padding: 0;
        margin: 0;
        background-color: $body-background;

        ul {
            background-color: $body-background;
        }
    }

    .dropdown {
        display: inline-block;
    }


    .menu > li > a {
        //color: scale-color($black, $lightness: 30%);
        color: $black;
        font-size: 0.85em;
        text-transform: uppercase;
        font-weight: bold;
        font-style: italic;
        letter-spacing: 1px;
        padding:1rem 0;
        @include transition;
    }

    .menu > li > a:hover {
        color: $black;
        @include transition;
    }

    .menu > li.navItem{
        display:inline-block;
        @include breakpoint(small) {
            margin: 0;
        }
        @include breakpoint(large) {
            margin: 0 0 0 2rem;
        }
    }
    .menu > li.is-dropdown-submenu-parent > a{
        /*outline:1px dotted red;*/
        padding-right: 1.5rem;
    }

    .navItem:last-child {
        /*padding: 1rem 0 1.5rem;*/
        /*margin: 0 0 0 1.5rem;*/
    }

    .submenu {
        margin-top: 10px;
        border-width: 0 0 10px 0;
        box-shadow: 0 8px 70px rgba($black, 0.3);
    }

    .submenu > li > a {
        color: scale-color($black, $lightness: 30%);
        padding: 1em 1em 1em 1.6em;
        letter-spacing: 0px;
        text-transform: none;
        font-weight: normal;
        font-style: normal;
        text-align: left;
        line-height: 2em;
        font-size: 0.9em;
        background-color: transparent;
        @include transition;
    }

    .submenu > li > a:hover {
        color: $black;
        background-color: scale-color($light-gray, $lightness: 45%);
    }

    .submenu > li > a.external-link:before{
        margin-right:0.3em;
        content: url('/assets/svg/external-link-icon-black.svg');
        opacity:0.3;
    }

    .menu > li.is-dropdown-submenu-parent > a::after {
        border-color: $nav-arrow-color transparent transparent;
    }

    .menu > li.is-accordion-submenu-parent > a::after {
        border-color: $nav-arrow-color;
    }

    .menu > li.is-accordion-submenu-parent {
        > a:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border: inset 5px;
            border-color: $nav-arrow-color transparent transparent;
            border-top-style: solid;
            border-bottom-width: 0;
            float: right;
            right: 5px;
            top: 15px;
            margin-top: -2px;
        }

        &.chosen {
            //background-color: $light-gray;
        }

        &.chosen > a:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border: inset 5px;
            border-color: transparent transparent $nav-arrow-color transparent;
            border-bottom-style: solid;
            border-top-width: 0;
            float: right;
            right: 5px;
            top: 15px;
            margin-top: -2px;
        }
    }
    .accordion-menu {
        margin: 2em 0 2em 0;
    }

    .accordion-menu li.navItem {
        border: 1px solid $light-gray;
        border-width: 1px 1px 0 1px;
        display:block;
        padding: 0.6em;

        a {
            padding:1rem;
        }
        ul li a {
            padding-left: 2em;
        }

        ul li {
            background-color: transparent;
            :hover {
                background-color: scale-color($light-gray, $lightness: 45%);
            }
        }
    }

    .accordion-menu li.navItem:last-child {
        border-width: 1px;
        padding:1rem;
    }

    .is-accordion-submenu {
        box-shadow: none;
    }

    .menu-icon {
        margin-top: 1rem;
    }

    .is-dropdown-submenu:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border: inset 13px;
        border-color: transparent transparent $body-background transparent;
        border-bottom-style: solid;
        border-top-width: 0;
        position: absolute;
        left: 1.5em;
        top: -13px;
    }
}



// Sub Navigation
// ---------

#mobile-sub-navigation {
    text-align: center;
    border-top: 1px solid $light-gray;
    background: #2C2F37;
}
#sub-navigation {
    background-color:#2C2F37;
    color: $white;
    padding: 0;
    font-size: 0.85rem;
    .menu > li > a {
        color: $white;
        padding: 1.2rem 1.5rem;
        margin: 0 0 0 1.5rem;

    }
    .menu > li > a:last-child {
        padding-right:0;
    }
    .menu > li > a:hover {
        opacity: 0.3;
        @include transition;
    }
    .top-bar-title {
        text-transform: uppercase;
        padding: 1rem 0;
        font-style:italic;
    }

    .top-bar-right {
        /*padding:0 3rem 0 0;*/
        ul {
            background-color: transparent;
            padding: 0;
            margin: 0;
        }

    }

}
// Footer
// ---------

#footer {
    background-color: $white;
    padding:3rem 0rem 3rem 0rem;
    border-top:1px solid $light-gray;

    .phone-number {
        color: $secondary-color;
        font-size: 0.8em;

        img {
            margin-right: 1em;
        }
    }

    .social-icon {
        @include breakpoint(small) {
            margin: 0 0 0 1rem;
        }
        @include breakpoint(medium) {
            margin: 0 0 0 2rem;
        }
    }

    .social-icon:first-child {
        margin: 0;
    }

    h6 {
        font-size: 0.9em;
        font-style: italic;
        text-transform: uppercase;
    }

    a {
        font-size: 0.9em;
        color: scale-color($black, $lightness: 30%);
    }

    a:hover {
        color: $black;
    }

    .menu > li > a {
        padding: 0.5em 0;
        text-align: left;
        color: $dark-gray;
        line-height: 1.5;
        font-size: 0.9em;
        @include transition;
    }

    .menu > li > a:hover {
        color: $black;
    }

    .menu > li > a.external-link:after{
        margin-left:0.3em;
        content: url('/assets/svg/external-link-icon-black.svg');
        opacity:0.3;
    }
    .menu {
        padding-bottom: 2em;
    }
}

#copyright-info {
    background-color: #F4F5F7;
    padding: 1.5rem;
    font-size: 0.8em;

    a {
        color: $black;
        @include breakpoint(small) {
            padding: 1rem 0;
            border-left: 0 solid transparent;
            display: block;
        }
        @include breakpoint(large) {
            padding: 0 0.5rem;
            border-left: 1px solid $medium-gray;
            display: inline-block;
        }
    }

    a:last-child {
        @include breakpoint(large) {
            padding: 0 0 0 0.5rem;
        }
    }

    a:first-child {
        border-left: 0 solid transparent;
    }
}

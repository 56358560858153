
#page-hero {
    position:relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: $secondary-color;
    width: 100%;
    overflow: hidden;
    /*@include inverted;*/
    .hero-container {
        position:relative;
        display:block;
    }
    .size-large {
        @include breakpoint(large) {
            height:700px;
        }
        @include breakpoint(medium only) {
            height:600px;
        }
        @include breakpoint(small only) {
            height:500px;
        }

    }
    .size-medium {
        @include breakpoint(large) {
            height:600px;
        }
        @include breakpoint(medium only) {
            height:500px;
        }
        @include breakpoint(small only) {
            height:400px;
        }

    }
    .size-small {
        @include breakpoint(large) {
            height:400px;
        }
        @include breakpoint(medium only) {
            height:300px;
        }
        @include breakpoint(small only) {
            height:200px;
        }
    }
    .hero-cta {
        width:70%;
        position:absolute;
        text-transform: uppercase;
        margin:5rem 0 2.5rem;
    }
    .position-top-left {
        width:50%;
        text-align:left;
        left:0;
        top:0;
    }
    .position-top-center {
        text-align:center;
        left:15%;
        top:0;
    }
    .position-top-right {
        text-align:right;
        right:0;
        top:0;
    }
    .position-bottom-left {
        text-align:left;
        left:0;
        bottom:0;
    }
    .position-bottom-center {
        text-align:center;
        left:15%;
        bottom:0;
    }
    .position-bottom-right {
        text-align:right;
        right:0;
        bottom:0;
    }
    .light-text {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            color: $white;
        }
    }
    .dark-text {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            color: $header-color;
        }
    }
}

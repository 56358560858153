#build{
    @include section-spacing;
}

#step-links {
    padding-bottom: 1em;
    a {
        color: $dark-gray;
        font-size: 0.8em;
    }
}

#build-form {
    background-color: $white;
    padding: 3em;
    .active {
        background: #F7F7F7;
    }
    #selection-box {
        padding: 0.6em 0 0.6em 0;
        p {
            margin-bottom: 0;
        }
        input {
            margin:0;
        }
    }
    input[type='text'] {
        background-color: $white;
        border: 1px solid #CACACA;
        color: $black;
    }
    select {
        background-color: $white;
        border: 1px solid #CACACA;
        color: $black;
    }
    #build-next-previous {
        padding-top: 3em;
    }
    @include breakpoint (large) {
        padding:3em;
    }
    @include breakpoint (small) {
        padding:1.5em;
    }
}
#build-summary {
    background: $white;
    .help-link {
        text-align: center;
    }
    tbody {
        font-size: 0.9em;
        border: none;
    }
    .total {
        color: $black;
        line-height: 2.5;
        font-weight: 700;
    }
    @include breakpoint (large) {
        margin-top: 0em;
        padding:2em;
    }
    @include breakpoint (small) {
        margin-top: 2em;
        padding:1.5em;
    }
}

.build-disclaimer {
    padding-top: 2em;
    text-align: center;
    font-size: 0.7em;
}

@charset 'utf-8';
@import 'settings';
@import 'foundation'; //@import 'motion-ui';
@import 'vendor/owlcarousel';
@import 'vendor/lightgallery';
@include foundation-global-styles;
@include foundation-grid; // @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms; // @include foundation-range-input;
@include foundation-accordion; //@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu; //@include foundation-flex-video;
//@include foundation-label;
@include foundation-media-object; //@include foundation-off-canvas;
//@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar; // @include foundation-progress-element;
// @include foundation-meter-element;
//@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal; //@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes; // @include foundation-flex-classes;
//@include motion-ui-transitions;
//@include motion-ui-animations;
@include table-scroll; // ------------- Mixins ------------- //
@mixin transition {
    transition: all 0.3s ease;
}
@mixin grow {
    transform: scale(1.05);
    transition: all 0.2s ease;
}
@mixin section-spacing {
     @include breakpoint(large){
        padding: 4rem 2rem;
    }
    @include breakpoint(small) {
        padding: 4rem 1rem;
    }
}
@mixin inverted {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: $white;
    }
}

@mixin category-text-style {
    p {
        text-transform: uppercase;
        color: $dark-gray;
        font-size: 0.8em;
        padding-top: 0.2em;
    }
}

.fade {
    -webkit-filter: brightness(100%);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.fade:hover {
    -webkit-filter: brightness(75%);
}
// ------------- Site Settings ------------- //

body {
    background-color: rgb(239, 239, 239);
}
$nav-arrow-color: $primary-color;

#site {
    width: 100%;
    max-width: rem-calc(1600);
    margin: 0 auto;
    background-color: $body-background;
}
// ------------- Typography ------------- //
@import url( 'https://fonts.googleapis.com/css?family=Roboto:300,400,700');

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
}

.subheader {
    font-size: 100%;
}

blockquote {
    font-family: 'Crimson Text', Times, serif;
    @include breakpoint(large) {
        font-size: 3rem;
        line-height: 1.5;
    }
    @include breakpoint(small) {
        font-size: 2rem;
        line-height: 1.5;
    }
}

cite {
    font-size: 0.5em;
    padding-top: 2em;
}

#section-title {
    padding-bottom: 1em;
    margin: 0 0 2.5em;
    text-transform: uppercase;
    font-weight: bold;
    color: $black;
}

#section-title-large {
    @include breakpoint(small only) {
        padding-bottom: 2em;
    }
}

.bottom-border {
    border-bottom: 1px solid $light-gray;
    padding-bottom:1em;
}

.price {
    font-weight: 700;
    //text-transform: uppercase;
    color: $black;
    margin-bottom: 0;
}

.spinner {
    //max-width: 30px;
    padding: 2em;
}
// ------------- Image Component ------------- //

.image-component {
    /*@include breakpoint(small) {
        padding: 0 0 2.5rem;
    }
    @include breakpoint(medium) {
        padding: 0 0 3.2rem;
    }*/
    width: 100%;
}
// ------------- Anchors ------------- //

.cta-link {
    display: block;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 2rem;
}
.cta-link.external-link:after{
    margin-left:0.5em;
    content: url('/content/dam/boating-center/svg/external-link-icon-black.svg');
    opacity:0.3;
}
// ------------- Buttons ------------- //

.button {
    text-transform: uppercase;
    font-weight: 600;
}

.button.primary:focus,
.button.primary:hover {
    background-color: rgba(255,184,0,1);
    color: #222222;
}
.button.external-link:after{
    margin-left:0.3em;
    content: url('/content/dam/boating-center/svg/external-link-icon-black.svg');
    opacity:0.3;
}
// ------------- Forms ------------- //

label {
    margin-right: 0.5em;
    position: relative;
    cursor: pointer;

    input[type='radio'] {
        position: absolute;
        left: 1em;
        top: 0.35em;
    }

    input[type='checkbox'] {
        position: absolute;
        left: 1em;
        top: 0.35em;
    }

    .radio-title {
        margin-left: 1em;
    }
}
select {
    background-color:$white;
}
// ------------- Accordion ------------- //

.accordion {
    margin-right: 0.5em;
    margin-top: 1em;
}

.accordion-title {
    font-size: 1em;
}
// ------------- Blockquote ------------- //

#testimony {
    background-image: url("/assets/svg/quote-bg.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    background-color: $white;
    @include section-spacing;
}

.quote-photo {
    background-image: url("/assets/images/gray-bg.png");
    height: 110px;
    width: 110px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    margin-bottom: 1em;
}
// ------------- Pagination ------------- //

.pagination {
    padding-top: 2em;
}
// ------------- Tabs ------------- //

.tabs {
    text-align: center;
    border-bottom:1px solid $light-gray;
    margin-top:1rem;
}

.tab-container {
    text-align: center;
    font-size: 0;
}

.tabs-title {
    display: inline-block;
    float: none;

    a {
        color: $black;
        font-weight:bold;
        font-style:italic;
        text-transform:uppercase;
        transition: all 0.2s ease-in-out;
        border-bottom:0.5rem solid transparent;
        padding: 1rem 2rem;
        margin:0 1rem;
    }

    &.is-active a {
        color: $black;
        border-bottom:0.5rem solid $primary-color;
    }
}

.tab-row {
    display: inline-block;
}
// ------------- Expanded Background Color Section -------- //

#background-color-section {
    background-color: rgb(244, 245, 247);
}
// ------------- Expanded Background Color Section -------- //

#background-image-section {
    background-size: cover;
    background-position: 75% center;
    background-blend-mode: lighten;
    @include breakpoint(small) {
        background-color: rgba(244, 245, 247, 0.8);
    }
    @include breakpoint(medium) {
        background-color: rgba(244, 245, 247, 0.6);
    }
    @include breakpoint(large) {
        background-color: rgba(244, 245, 247, 0.2);
    }

}



// ------------- Recent News ------------- //

#article-item {
    /*background-color: rgb(244, 245, 247);*/
    /*@include section-spacing;*/
    .view-all {
        float: right;
        font-style: italic;
    }

    .article-back-link {

        a {
            color: $dark-gray;
            font-weight: bold;
            text-transform: uppercase;

        }

        a:hover {
            color: $black;
        }

        a:before {
            content: '';
            display: inline-block;
            width: 0;
            height: 0;
            border: 5px inset;
            border-color: transparent $nav-arrow-color transparent transparent;
            border-top-style: solid;
            border-left-width: 0;
            margin-right: 0.5rem;

        }

    }





    .article-block {
        @include breakpoint(small) {
            margin-bottom: 0;
        }
        @include breakpoint(medium) {
            margin-bottom: 1rem;
        }
        @include breakpoint(large) {
            margin-bottom: 4rem;
        }
    }

    .article-image {
        display: block;
        @include breakpoint(large) {
            height: 20rem;
        };
        @include breakpoint(small) {
            height: 12rem;
        }

        .thumbnail {
            //background-image: url(/assets/images/gray-bg.png);
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;
        }
    }

    .article-data {
        background-color: $white;
        padding: 2rem;
        border: 1px solid $light-gray;
        border-width: 0 1px 1px 1px;
    }

    .date-location {
        color: $dark-gray;
        font-weight: bold;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
    }

    .article-cta {
        a {
            color: $dark-gray;
            font-weight: bold;
            text-transform: uppercase;
        }

        a:hover {
            color: $black;
        }

        a:after {
            content: '';
            display: inline-block;
            width: 0;
            height: 0;
            border: 5px outset;
            border-color: transparent transparent transparent $nav-arrow-color;
            border-top-style: solid;
            border-right-width: 0;
            margin-left: 0.5rem;
            margin-top: -2px;
        }
    }
}
// ------------- Lead Form / Brochure Request ------------- //

.is-invalid-input:not(:focus) {
    background-color: rgba($black, 0.4);
    border: 1px solid rgba($alert-color, 0.5);
}

#lead-form {
    background-color: $secondary-color;
    text-align: center;
    @include section-spacing;
    @include inverted;
}

#request-form {
    margin: 3em 0;
}

#brochure-form {
    background-color: #404A53;
    text-align: left;
    @include section-spacing;
    @include inverted;

    label {
        color: $white;
    }
}

#brochure-download {
    padding-bottom: 4em;
}

#brochure-submit {
    padding-top: 2em;
}
// ------------- Content ------------- //

.content {
    @include section-spacing;
}

.article-content {
    background-color: $white;
    //margin-top: 1.5em;
    @include section-spacing;

    h3 {
        padding-top: 1.5em;
        padding-bottom: 0;
    }

    a {
        font-size: 0.8em;
    }
    @include breakpoint (small only) {
        margin-top: 0;
    }
}

.introduction {
    font-size: rem-calc(18);
}


.ttaBlock .content {
    padding:0;
    #section-title {
        border-bottom: 1px solid $light-gray;
        padding-bottom:1em;
    }
}

#model-build-price {
    background-color: $white;
    @include section-spacing;
}

#series-overhead {
    padding-top: 4vh;
    padding-bottom: 4vh;

    .owl-nav {
        display: none;
    }
}

#full-width-gallery {
    .owl-dot {
        display: none;
    }
}

#model-specs {
    background-color: $secondary-color;
    @include inverted;
    @include section-spacing;

    #spec-category {
        @include category-text-style;
    }

    #spec-stat {
        p {
            font-size: 0.9em;
        }
    }
}

,
#model-features {
    background-color: $white;
    @include section-spacing;

    #feature-section {
        margin-bottom: 3em;
    }

    #feature-category {
        @include category-text-style;
    }

    #feature-description:nth-child(odd) {
        background-color: rgba($black, 0.05);
    }

    #feature-description {
        p {
            line-height: 1.5em;
            padding: 1em;
            margin-bottom: 0;
        }
    }
}

#model-options {
    background-color: $primary-color;
    @include section-spacing;
    @include inverted;

    #option-section {
        margin-bottom: 3em;
    }

    #option-category {
        @include category-text-style;
        @include inverted;
    }

    .option-price {
        float: right;
        background-color: rgba($black, 0.0);
    }
}

#photo-gallery {
    padding: 0;
}

#lightgallery {
    @include breakpoint(small) {
        padding: 0 1em 4em;
    }
    @include breakpoint(large) {
        padding: 0 3em 4em;
    }
}

.video {
    padding-bottom: 2em;

    p {
        padding-top: 0.5em;
        margin-bottom: 0;
    }
}

#article-preview {
    padding-bottom: 3em;
}

#compare-models {
    #spec-category {
        font-size: 0.9em;
        text-transform: uppercase;

        p {
            margin-bottom: 1em;
            margin-top: 1.3em;
        }
    }

    #spec-stat {
        p {
            font-size: 0.9em;
            margin-bottom: 1em;
            margin-top: 1em;
            //padding-left: 20%;
        }
    }

    #odd {
        background-color: #F7F7F7;
    }

    #model-title {
        margin-bottom: 2em;
    }

    .compare-model-title {
        //text-align: center;
        font-size: 1.2em;
        padding: 1em;
        font-weight: normal;
    }
}
// ------------- Product Set ------------- //

#product-set {
    @include section-spacing;

    #product-tile {
        text-align: center;
    }

    .product-tile-content {
        background-color: #ffffff;
        padding: 3em 0 0;
        margin: 1em 1.5em 2em;
        box-shadow: 0 0 50px rgba(0,0,0,0.06);

        :hover {
            box-shadow: none;
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
        }

        .thumbnail {
            padding: 1em;
        }
    }

    .product-tile-cta {
        opacity: 0;
        cursor: pointer;
        padding: 1em 0;
        margin: 2em 0 0;
        background-color: $alert-color;
        color: $white;

        p {
            color: $white;
            font-size: 0.8em;
            font-weight: 700;
            margin-bottom: 0;
            text-transform: uppercase;
        }
    }
}

#product-tile:hover {
    .product-tile-cta {
        opacity: 1;
        @include transition;
    }
    @include grow;
}

.build-series-name {
    @include breakpoint(large) {
        padding-top: 1.5em;
        padding-bottom: 0.5em;
        margin-left: 1.4em;
        text-align: left;
    }
    @include breakpoint(small) {
        text-align: center;
        padding-bottom: 1em;
    }
    padding-top: 1em;
}
// ------------- Find A Dealer ------------- //

#location-finder-cta {
    background-size: cover;
    background-repeat: no-repeat;

    #inner-content {
        color: $white;
        font-size: 1.6rem;
        font-weight: 100;
        @include breakpoint(small) {
            padding-bottom:2rem;
        }
        @include breakpoint(large) {
            padding-bottom:0;
        }
    }

    #inner-button {
        .button {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            border-radius: 2rem;
            border-width: 2px;
            font-style: italic;
            margin-bottom:0;
        }
    }
}

#find-a-dealer-search {
    /*background-color: $secondary-color;*/
    /*@include inverted;*/
    //@include section-spacing;
    padding-top: 2em;
    padding-bottom: 2em;
    border: 1px solid $light-gray;

    input {
        display: inline-block;
        width: auto;
        height: auto;
        margin: 1rem 0;
    }

    input[type='search'] {
        width: 80%;
        max-width: 20rem;
    }

    input[type='submit'] {
        margin: -0.2rem 0 0;
        color: $white;
        background-color: $black;
        border-radius: 0;
    }

    label {
        display: inline-block;
        font-size: rem-calc(16);
        font-weight: bold;
        line-height: 2.5rem;
        margin: 1rem;
    }

    .search-toggle {
        padding-top: 1em;
    }
}

#map {
    height: 415px;
    width: 100%;
    max-height: none;
}

#map {
    @include breakpoint(small only) {
        display: none;
    }
}

#dealer-set {
    @include section-spacing;

    #dealer-result-count {
        h3 {
            color: $dark-gray;
            font-style: normal;
            font-weight: 100;
            margin: 0 0 3rem;
        }
    }

    #dealer-tile {
        text-align: left;
        /*background-color: #ffffff;*/
        padding: 3em 1em;
        margin: 1em 0;
        /*box-shadow: 0 0 50px rgba(0,0,0,0.06);*/
        border: 1px solid $light-gray;

        h6 {
            margin-top: 0.4em;
            margin-bottom: 1.5rem;
        }

        p {
            padding: 0;
            margin-bottom: 1rem;
        }

        :hover {
            box-shadow: none;
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
        }

        .address {
            margin-bottom: 2rem;
        }

        .hours {
            margin-bottom: 2rem;
        }

        .phone {
            margin-bottom: 2rem;
        }

        .info {
            font-size: 0.9em;
            line-height: 1.8em;
            color: $black;
            margin: 0;
        }

        .button.hollow {
            border-width: 2px;
        }
    }

    .selected {
        /*background-color: $alert-color;*/
    }
}

.map-pin {
    background-image:url('/assets/svg/map-pin.svg');
    background-repeat:no-repeat;
    text-align: center;
    vertical-align: middle;
    width: 20px;
    height: 32px;
    margin-bottom: 2em;

    p {
        color: $black;
        line-height: 2.3;
    }
}

// ------------- Owl Carousel ------------- //

.owl-carousel .owl-nav .owl-prev {
    opacity: 0.8;
    width: 52px;
    height: 52px;
    display: block;
    position: absolute;
    right: 158px;
    bottom: -2px;
    @include breakpoint(small only) {
        height: 30px;
        width: 30px;
        right: 0;
        left: 0;
    }
}

.owl-carousel .owl-nav .owl-next {
    opacity: 0.8;
    width: 52px;
    height: 52px;
    display: block;
    position: absolute;
    right: 100px;
    bottom: -2px;
    @include breakpoint(small only) {
        height: 30px;
        width: 30px;
        right: 0;
    }
}

.owl-dots {
    text-align: center;
    //position: fixed;
    bottom: 5px;
    width: 100%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
}

.owl-dot {
    border-radius: 50px;
    height: 15px;
    width: 15px;
    display: inline-block;
    background: rgba($medium-gray, 0.5);
    margin-left: 10px;
    margin-right: 10px;
}

.owl-dot.active {
    background: rgba($medium-gray, 1);
}

#video-thumbnail {
    width: 160px;
    @include breakpoint (small only) {
        width: 100px;
        display: none;
        text-align: center;
    }
}

.gallery-thumb {
    padding: 4px;
}

.video-play-button {
    position: absolute;
    top: 20%;
    left: 30%;
    height: 50px;
    width: 50px;
}
@import 'components/navigation';
@import 'components/hero';
@import 'components/tta';
@import 'components/build';

#home-tta-block-one {
    background-image:
        linear-gradient(rgba(52, 61, 70, 0.2), rgba(52, 61, 70, 0.2)),
        url("/assets/images/tta-1.png");
    background-repeat: no-repeat;
    background-position: center 0;
    background-size: cover;
    background-color: $secondary-color;
    color: $white;
    height:380px;
    text-align: center;
    padding-top: 180px;
    @include inverted;
}
#home-tta-block-two {
    background-image:
        linear-gradient(rgba(52, 61, 70, 0.2), rgba(52, 61, 70, 0.2)),
        url("/assets/images/tta-1.png");
    background-repeat: no-repeat;
    background-position: center 0;
    background-size: cover;
    background-color: $secondary-color;
    color: $white;
    height:380px;
    text-align: center;
    padding-top: 180px;
    @include inverted;
}
